<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">중복확인</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-list" v-if="pop.total>0">
                    <colgroup>
                        <col width="*">
                        <col width="120">
                        <col width="120">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>고객사</th>
                            <th>이름</th>
                            <th>연락처 2</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(irow, i) in pop.list" :key="i">
                            <td><router-link :to="{ name:'customerManagement-businessCardMod-idx', params:{ idx:irow.idx } }" target="_blank"><span class="btn-view">{{ irow.company_kname }}</span></router-link></td>
                            <td><router-link :to="{ name:'customerManagement-businessCardMod-idx', params:{ idx:irow.idx } }" target="_blank"><span class="btn-view">{{ irow.kname }}</span></router-link></td>
                            <td>{{ irow.mobile }}</td>
                        </tr>
                    </tbody>
                </table>
                <div v-else class="pop-body">
                    <p class="pt-10 pb-20 txt-center">명함 등록이 가능합니다.</p>
                    <div class="txt-center">
                        <button type="button" @click="pop.onSuccess" class="btn-default dp-inblock">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'DoubleCheck2Popup',
    props: {
        modelValue: {
            type: String,
            default: "N",
        },
        bizname: {
            type: String,
            default: "",
        },
        idx_company: {
            type: [String, Number],
            default: 0,
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            bname : "",
            list  : "",
            total : 0,

            doSearch: () => {
                let params = {
                    idx   : pop.idx_company,
                    bname : pop.bname
                };

                axios.get("/rest/customermgr/getLikeBizcardList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        pop.list  = res.data.list;
                        pop.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onSuccess: () => {
                emit('update:modelValue', 'Y');
                emit('close');
            },

            onClose: () => {
                emit('update:modelValue', 'N');
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx_company = props.idx_company;
            pop.bname = props.bizname;

            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>