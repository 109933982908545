<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <DoubleCheck2Popup :idx_company="pop.idx_company" :bizname="pop.bname" v-model="pop.isAble" v-if="pop.isShowCheck" @close="pop.hideCheck"></DoubleCheck2Popup>

        <CarrotTitle title="명함 등록"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사/이름</th>
                                <td colspan="3">
                                    <carrot-company v-model="add.idx_company" v-model:zipcode.sync="add.zipcode" v-model:address.sync="add.addr" v-model:address_sub.sync="add.addr_sub" class="w-250px float-left mr-5"></carrot-company>
                                    <input type="text" v-model.trim="add.kname" class="w-200px float-left mr-5" placeholder="이름을 입력하세요.">
                                    <button class="btn-default float-left h-30px" @click="pop.showCheck">이름 중복확인</button>
                                </td>
                            </tr>
                            <tr>
                                <th>담당자 정보</th>
                                <td colspan="3">
                                    <div>
                                        <label v-for="(irow, i) in add.mtype_list" :key="i"><input type="checkbox" v-model="add.mtype" :value="irow.code"><span class="ml-5 mr-20"> {{ irow.name }}</span></label>
                                    </div>
                                    <div class="mt-5">
                                        <span class="mr-20">VIP</span>
                                        <label><input type="radio" v-model="add.is_vip" value="Y"><span class="ml-5 mr-20"> Y</span></label>
                                        <label><input type="radio" v-model="add.is_vip" value="N"><span class="ml-5 mr-20"> N</span></label>
                                    </div>
                                    <div class="mt-5">
                                        <span class="mr-20">메인 담당자</span>
                                        <label><input type="radio" v-model="add.is_main" value="Y"><span class="ml-5 mr-20"> Y</span></label>
                                        <label><input type="radio" v-model="add.is_main" value="N"><span class="ml-5 mr-20"> N</span></label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>부서</th>
                                <td colspan="3">
                                    <input type="text" class="w-80per" v-model="add.part">
                                </td>
                            </tr>
                            <tr>
                                <th>직급</th>
                                <td colspan="3">
                                    <input type="text" class="w-80per" v-model="add.position">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> E-mail</th>
                                <td colspan="3">
                                    <input type="text" class="w-80per" v-model="add.email">
                                </td>
                            </tr>
                            <tr>
                                <th>Tel</th>
                                <td>
                                    <input type="text" class="w-100per" v-model="add.tel">
                                </td>
                                <th>Mobile</th>
                                <td>
                                    <input type="text" class="w-100per" v-model="add.mobile">
                                </td>
                            </tr>
                            <tr>
                                <th>Fax</th>
                                <td colspan="3">
                                    <input type="text" class="w-200px" v-model="add.fax">
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 주소</th>
                                <td colspan="3">
                                    <carrot-address v-model:zipcode.sync="add.zipcode" v-model:address.sync="add.addr"></carrot-address>
                                    <input type="text" v-model.trim="add.addr_sub" class="w-100per mt-5" placeholder="상세주소를 입력하세요.">
                                  <br> <span class="txt-red"> *고객사 정보에 있는 주소로 명함이 등록됩니다. 항상 최신으로 업데이트 해주세요.</span>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 사보발송 여부</th>
                                <td colspan="3">
                                    <label><input type="radio" v-model="add.is_newsletter" value="Y"><span class="ml-5 mr-20"> 발송</span></label>
                                    <label><input type="radio" v-model="add.is_newsletter" value="N"><span class="ml-5 mr-20"> 미발송</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>사보발송 주소</th>
                                <td colspan="3">
                                    <label><input type="checkbox" v-model="add.is_nl_same" value="Y" @change="add.changeNL"><span class="ml-5 mr-20"> 기본 주소와 동일</span></label><br>
                                    <div v-if="!add.is_nl_same">
                                        <carrot-address v-model:zipcode.sync="add.nl_zipcode" v-model:address.sync="add.nl_addr"></carrot-address>
                                        <input type="text" v-model.trim="add.nl_addr_sub" class="w-100per mt-5" placeholder="상세주소를 입력하세요.">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> DM 발송 대상</th>
                                <td colspan="3">
                                    <label><input type="radio" v-model="add.is_dm" value="Y"><span class="ml-5 mr-20"> 발송</span></label>
                                    <label><input type="radio" v-model="add.is_dm" value="N"><span class="ml-5 mr-20"> 미발송</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 재직 여부</th>
                                <td colspan="3">
                                    <label><input type="radio" v-model="add.is_work" value="Y"><span class="ml-5 mr-20"> 재직중</span></label>
                                    <label><input type="radio" v-model="add.is_work" value="N"><span class="ml-5 mr-20"> 퇴사</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>특이사항</th>
                                <td colspan="3">
                                    <textarea class="w-100per h-100px" v-model.trim="add.special_memo"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>활성화 유무</th>
                                <td colspan="3">
                                    <label><input type="radio" v-model="add.is_use" value="Y"><span class="ml-5 mr-20"> 활성화</span></label>
                                    <label><input type="radio" v-model="add.is_use" value="N"><span class="ml-5 mr-20"> 비활성화</span></label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="add.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex";
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotAddress from '@/components/common/CarrotAddress.vue'
import CarrotCompany from '@/components/common/CarrotCompany.vue'
import DoubleCheck2Popup from '@/components/popup/customerManagement/DoubleCheck2Popup.vue'


export default {
    layout:"customerManagement",
    components: {
        CarrotCompany,
        CarrotAddress,
        DoubleCheck2Popup
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const store = new useStore();
        const toast = useToast()

        const pop = reactive({
            // Popup 노출 여부
            idx_company : 0,
            bname       : "",
            isAble      : "",
            isShowStep  : false,
            isShowCheck : false,

            showCheck : () => {
                if( !add.idx_company ){
                    Swal.fire('고객사를 입력해주세요.');
                    return;
                }

                if( !add.kname ){
                    Swal.fire('이름을 입력해주세요.');
                    return;
                }

                pop.isShowCheck = true;
                pop.idx_company = add.idx_company;
                pop.bname       = add.kname;
            },

            hideCheck : () => {
                pop.isShowCheck = false;
                console.log(pop.isAble);
            }
        });
        

        const add = reactive({
            mtype_list : [],

            idx_company : 0,
            mtype       : [],
            is_vip      : "N",
            is_main     : "Y",
            part        : "",
            position    : "",
            email       : "",
            tel         : "",
            mobile      : "",
            fax         : "",
            zipcode     : "",
            addr        : "",
            addr_sub    : "",

            is_newsletter : "Y",
            is_nl_same    : false,
            nl_zipcode    : "",
            nl_addr       : "",
            nl_addr_sub   : "",

            is_dm        : "Y",
            special_memo : "",
            is_use       : "Y",
            is_work      : "Y",

            getMtypeList : () => {
                let params = { };

                axios.get("/rest/customermgr/getMtypeList", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        add.mtype_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            changeNL : () => {
                if( add.is_nl_same == true ){
                    add.nl_zipcode  = add.zipcode;
                    add.nl_addr     = add.addr;
                    add.nl_addr_sub = add.addr_sub;
                } else {
                    add.nl_zipcode  = "";
                    add.nl_addr     = "";
                    add.nl_addr_sub = "";
                }
            },

            doSubmit : () => {
                let params = {
                    idx_company : add.idx_company,
                    kname       : add.kname,
                    ename       : add.ename,

                    mtype    : add.mtype,
                    is_vip   : add.is_vip,
                    is_main  : add.is_main,
                    part     : add.part,
                    position : add.position,
                    email    : add.email,
                    tel      : add.tel,
                    mobile   : add.mobile,
                    fax      : add.fax,
                    zipcode  : add.zipcode,
                    addr     : add.addr,
                    addr_sub : add.addr_sub,

                    is_newsletter : add.is_newsletter,
                    is_nl_same    : add.is_nl_same==true?"Y":"N",
                    nl_zipcode    : add.nl_zipcode,
                    nl_addr       : add.nl_addr,
                    nl_addr_sub   : add.nl_addr_sub,

                    is_dm        : add.is_dm,
                    special_memo : add.special_memo,
                    is_use       : add.is_use,
                    is_work      : add.is_work,
                };

                if( !add.idx_company ){
                    toast.error("고객사를 입력해주세요.");
                    return;
                }
                if( !add.kname ){
                    toast.error("이름을 입력해주세요.");
                    return;
                }
                if( pop.isAble == "N" ){
                    toast.error("이름 중복확인을 해주세요.");
                    return;
                }

                let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                if ( add.email == '' || !re.test(add.email) ) {
                    toast.error("올바른 이메일 주소를 입력하세요.");
                    return;
                }

                if( !add.zipcode || !add.addr || !add.addr_sub ){
                    toast.error("필수 항목을 입력해주세요.");
                    return;
                }

                if( !add.is_newsletter ){
                    toast.error("필수 항목을 입력해주세요.");
                    return;
                }

                if( !add.is_dm || !add.is_work){
                    toast.error("필수 항목을 입력해주세요.");
                    return;
                }

                axios.post("/rest/customermgr/addBizcard", params).then((res) => {
                    if( res.data.err == 0 ){
                        router.push({
                            name : "customerManagement-businessCardView-idx",
                            params : {
                                idx : res.data.idx
                            }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doCancel: () => {
                router.go(-1);
            },

        });

        onMounted(() => {
            // Mounted
            add.idx_company = route.params.idx_company?route.params.idx_company:0;
            console.log(route.params, add.idx_company);

            add.getMtypeList();

            //스토어의 비즈카드 정보를 가져와서 바인딩한다.
            if(store.state.bizCardInfo.manager && store.state.bizCardInfo.manager != ''){
                add.kname = store.state.bizCardInfo.manager;
            }
            if(store.state.bizCardInfo.email && store.state.bizCardInfo.email != ''){
                add.email = store.state.bizCardInfo.email;
            }
            if(store.state.bizCardInfo.phone && store.state.bizCardInfo.phone != ''){
                add.mobile = store.state.bizCardInfo.phone;
            }
            store.commit("clearBizCardInfo");
        });

        return {pop, add};
    }
}
</script>

<style lang="scss" scoped>
</style>